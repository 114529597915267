import React, { useRef } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import Image from 'gatsby-plugin-sanity-image'

interface CarouselImage {
  asset: {
    fluid: FluidObject
  }
  alt: string
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      sanitySettings {
        carousel {
          alt
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
        }
        carousel_auto_play
        carousel_auto_play_speed
      }
    }
  `)

  const sliderRef = useRef(null)

  const handleSlickNext = () => {
    sliderRef?.current.slickNext()
  }

  const handleSlickPrev = () => {
    sliderRef?.current.slickPrev()
  }

  const {
    carousel,
    carousel_auto_play,
    carousel_auto_play_speed,
  } = data.sanitySettings

  return (
    <Layout>
      <SEO title="Home" />
      <div className="carousel-container">
        <Slider
          arrows={false}
          fade
          speed={800}
          autoplay={carousel_auto_play}
          autoplaySpeed={carousel_auto_play_speed || 3000}
          ref={sliderRef}
        >
          {carousel.map((img: CarouselImage, index: number) => (
            <div key={index} className="carousel">
              <Img
                fluid={{ ...img.asset.fluid }}
                className="carousel-image"
                fadeIn
                loading="auto"
                durationFadeIn={800}
                alt={img.alt}
                backgroundColor="#BDB9B2"
              />
              <button
                type="button"
                className="carousel-side-right"
                aria-label="Next slide"
                onClick={handleSlickNext}
              />
              <button
                type="button"
                className="carousel-side-left"
                aria-label="Previous slide"
                onClick={handleSlickPrev}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div id="shadow">
        <svg
          width="1500"
          height="398"
          viewBox="0 0 1500 398"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <rect
            width="1500"
            height="398"
            fill="url(#paint0_linear)"
            fillOpacity="0.2"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="750"
              y1="0"
              x2="750"
              y2="398"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div id="bottom-shadow">
        <svg
          width="1500"
          height="398"
          viewBox="0 0 1500 398"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <rect
            width="1500"
            height="398"
            fill="url(#paint1_linear)"
            fillOpacity="0.2"
          />
          <defs>
            <linearGradient
              id="paint1_linear"
              x1="750"
              y1="0"
              x2="750"
              y2="398"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopOpacity="0" />
              <stop offset="1" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </Layout>
  )
}
export default IndexPage
